.admin-page-wrapper{
    /* background-color: rgb(7, 7, 7); */
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.admin-page-wrapper > *{
    flex: 0 0 auto;
}
.admin-page-wrapper > *:last-child{
    flex: 1;
}
@media screen and (max-width:768px) {
    .admin-page-wrapper{
        min-height: 100vh;
        height: fit-content;
    }
}