.statistics-wrapper{
    background-color: red;
    box-sizing: border-box;
    padding: 10px 50px;
    padding-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: fit-content;
}