/* .statistic{
    background-color: blue;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
.value{
    font-size: 2em;
    color: white;
} */
.statistic{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: fit-content;

    flex: 1 1 0;
}
h2{
    margin: 0;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.8rem;
    max-width: 100px;
}